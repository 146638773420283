body {
  font-family: Arial, sans-serif;
}
.mode-select {
  background: #f8f8f8;
  max-width: 600px;
  border: 2px solid #ccc;
  padding: 8px 8px;
  border-radius: 4px;
  margin: 0 auto;
  >div {
    padding: 4px 0;
    display: flex;
    justify-content: center;
  }
  .upload {
    position: relative;
    height: 45px;
    width: 250px;
    &::before {
      content: "Izaberite json";
      background: #1a0dab;
      color: white;
      line-height: 45px;
      text-align: center;
      text-transform: uppercase;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      border-radius: 4px;
      font-weight: bold;
    }
    input {
      position: relative;
      width: 100%;
      height: 45px;
      display: block;
      opacity: 0;
      cursor: pointer;
    }
  }
  button {
    height: 35px;
    width: 350px;
    background: white;
    color: #1a0dab;
    text-align: center;
/*     text-transform: uppercase; */
    font-size: 16px;
    -webkit-apperance: none;
    border: 2px solid #1a0dab;
    border-radius: 4px;
    cursor: pointer;
  }
}
a, a:visited {
  color: #1a0dab;
  text-decoration: none;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
div.report {
  max-width: 500px;
  margin: 0 auto;
  border: 2px solid #ccc;
  padding: 8px;
  border-radius: 4px;
}
.tins {
  li {
    display: flex;
    padding: 4px 0;
    &:first-of-type {
      font-weight: bold;
      padding-bottom: 3px;
      margin-bottom: 8px;
      border-bottom: 1px solid #ccc;
      background: transparent !important;
    }
    &:hover {
      background: #eee;
    }
    em {
      font-style: normal;
    }
    >a {
      display: flex;
      width: 100%;
    }
    span, em {
      width: 50%;
      max-width: 200px;
      display: block;
    }
  }
}
ul.report {
  li {
    display: flex;
    padding: 4px 0;
    span {
      display: block;
      width: 150px;
      margin-right: 8px;
      text-align: right;
      font-weight: bold;
    }
    &:nth-of-type(2n) {
      background: #f8f8f8;
    }
    &:hover {
      background: #eee;
    }
  }
}
